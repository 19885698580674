<template>
  <v-container fill-height fluid>
    <v-row justify="center">
      <v-col cols="12" md="6" lg="3">
        <v-card outlined color="support_cards_bg">
          <v-card-title>
            <v-icon class="mr-2">mdi-information-outline</v-icon>
            Verification
          </v-card-title>

          <v-card-text>
            <span>
              We've sent you a verification email in order to confirm your newly
              created account. The email must contain 6 digit code, which you
              must enter below in order to complete your account.
            </span>
          </v-card-text>
        </v-card>

        <v-form class="mt-5">
          <v-otp-input :rules="codeRules" v-model="code" length="6" />
          <v-btn color="#286aff" block @click="verify()">Verify</v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "VerifyEmail",

  created() {},

  methods: {
    //343794
    async verify() {
      try {
        var session = JSON.parse(localStorage.session);
      } catch (err) {
        window.location.href = "../auth";
      }

      if (!session.username || !session.toverify) {
        window.location.href = "../auth";
        return;
      }

      if (!this.code || this.code.length != 6) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: "Your verification code is invalid",
        });

        return;
      }

      const data = {
        username: session.username,
        token: this.code,
        captcha: ''
      };

      const req = await this.$http
        .post("/public/verify", data)
        .then((res) => res.data);

        console.log(req)
      if (req.status === false) {
        this.$dtoast.pop({
          preset: "error",
          heading: `Ooops!`,
          content: req.message,
        });

        return;
      }

      this.$dtoast.pop({
        preset: "success",
        heading: `Success`,
        content: req.message,
      });

      delete localStorage.session;
      localStorage.session = JSON.stringify({ username: session.username });

      setTimeout(() => {
        window.location.href = "../auth";
      }, 1000);
    },
  },

  data() {
    return {
      codeRules: [
        (v) => !!v || "Verification Code is required",
        (v) => v.length == 6 || "The verification code must be of 6 digit",
      ],
      code: "",
    };
  },
};
</script>
